import '../styles/index.scss';

let Flickity = require('flickity');

$(()=>{
	if ($("body").hasClass("home"))
	{
		$("#stats-carousel").append($("#stats-carousel").children().clone(true,true));
	
		window.statsCarousel = new Flickity( "#stats-carousel", {
			cellAlign: 'center',
			prevNextButtons: false,
			wrapAround: true,
			freeScroll: false,
			contain: true,
			pageDots: false,
			draggable: true,
			imagesLoaded: false,
			autoPlay: 3000,
			selectedAttraction: 0.01,
			friction: 0.3
		  });
	}
	
	$("nav#top a.menu").on("click", (e)=>{
		$("nav#top").addClass("open");
		e.preventDefault();
	});
	
	$("nav#top a.close").on("click", (e)=>{
		$("nav#top").removeClass("open");
		e.preventDefault();
	});
	
	
	
	$("body").attr("style", "");
	
	window.scale = 1;
	
	let handleScroll = ()=>{
		
		if (window.mode == "desktop")
		{
			if (window.scrollY / window.scale > 840) {
				$("body").addClass("scrolled");
			}
			else
			{
				$("body").removeClass("scrolled");
			}
		}
		else
		{
			if (window.scrollY / window.scale > 845) {
				$("body").addClass("scrolled");
			}
			else
			{
				$("body").removeClass("scrolled");
			}
		}	
	};
	
	let handleResize = ()=>{
		let prHeight = $(".page-wrap").height();
		
		if ($(window).width() >= 1920)
		{
			$(window).attr("style", "");
			$(".page-wrap").attr("style", "transform: scale(1);");
			$(".page-wrap-outter").attr("style","");
			
			$(".sticky-header").attr("style", "transform: scale(1);");
		}
		else if ($(window).width() > 750)
		{
			window.scale = $(window).width()/1920;
			$(".page-wrap").attr("style", "transform: scale("+scale+");");
			$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			
			$(".sticky-header").attr("style", "transform: scale("+scale+");");
		}
		
		if ($(window).width() > 750)
		{
			if (window.mode != "desktop")
			{
				window.mode = "desktop";
				
			}
		}
		else
		{
			
			window.scale = $(window).width()/750;
		
			$(".page-wrap").attr("style", "transform: scale("+scale+")");
			$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			
			$(".sticky-header").attr("style", "transform: scale("+scale+");");
			
			if (window.mode != "mobile")
			{
				window.mode = "mobile";
			}
		}
		
		
		if ($("body").hasClass("home")) {
			window.statsCarousel.resize();
		}
		
		handleScroll();
	};
	
	
	
	$(window).on("resize",()=>{ handleResize(); });
	handleResize();
	
	setTimeout(()=>{
		handleResize();
	}, 500);
	
	
	
	$(window).on("scroll",()=>{ handleScroll(); });
	handleScroll();
	
	
	const validateEmail = (email) => {
	  return email.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  );
	};
	
	let emailFocus = "Add your email for updates";
	let emailUnfocus = "Get Turnaway Project Updates"
	
	$("form#signup-form input#email").on("focus", ()=>{ 
		
		$("form#signup-form input#email").attr("placeholder", emailFocus);
	});
	$("form#signup-form input#email").on("blur", ()=>{ 
		$("form#signup-form input#email").attr("placeholder", emailUnfocus);
	});
	
	$("form#signup-form").on("submit", (e)=>{
		let email = $("form#signup-form input#email").val();
		let checked =$("form#signup-form input#opt-in").is(':checked');
		
		e.preventDefault();
		
		console.log("form submit");
		
		if (email.length == 0)
		{
			$("#form-error").text("Email address is required!")
			$("#form-error").show();
		}
		else if (! validateEmail(email))
		{
			$("#form-error").text("Invalid email address!")
			$("#form-error").show();
		}
		else if (! checked)
		{
			$("#form-error").text("Must check agree checkbox to submit!");
			$("#form-error").show();
		}
		else
		{
			$("form#signup-form input#email").val("");
			$("form#signup-form input#email").attr("placeholder", emailUnfocus);
			$("#form-error").hide();
			
			$(".ctct-inline-form input").val(email);
			$(".ctct-inline-form form").submit();
		}
	});
	
	
	setInterval(()=>{
		if ($(".ctct-inline-form  .ctct-form-success").length && ($(".ctct-inline-form .ctct-form-success").attr("style").length == 0 || $(".ctct-inline-form .ctct-form-success").attr("style") == "display: block;"))
		{
			$("form#signup-form").hide();
			
			$("#form-error").show();
			$("#form-error").attr("style","margin-top: 0;")
			$("#form-error").text("Thank you for joining our mission to change the conversation about abortion.");
			
			handleResize();
		}
	}, 500);
	
	$("a").on("click",(e)=>{
		let link = $(e.currentTarget).attr("href");
		
		
		if (link.length > 0 & link[0] == "#")
		{
			let targetName = link.slice(1);
			let target = $("a[name='"+targetName+"']");
			
			
			if (target.length > 0)
			{
				let headerHeight = $(".sticky-header").height()+$("nav#top").height()
			
				handleResize();
				$([document.documentElement, document.body]).animate({
					scrollTop: target.offset().top-headerHeight
				}, 500);
				
				e.preventDefault();
			}
		}
	});
	
	if (window.location.hash)
	{
		let link = window.location.hash;
		if (link.length > 0 & link[0] == "#")
		{
			let targetName = link.slice(1);
			let target = $("a[name='"+targetName+"']");
			
			
			
			if (target.length > 0)
			{
				window.scrollTo(0, 0)
				
				setTimeout(()=>{
					let headerHeight = $(".sticky-header").height()+$("nav#top").height()
			
					handleResize();
					$([document.documentElement, document.body]).animate({
						scrollTop: target.offset().top-headerHeight
					}, 500);
				}, 750);
				
				
			}
		}
	}
	
});